import React from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";

function MoneyInput(props) {
  const { label, currency, register, loading, ...restProps } = props;

  function currencyCode() {
    return {
      uah: "₴",
      usd: "$",
    }[currency];
  }

  return (
    <>
      <label
        htmlFor="price"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{currencyCode()}</span>
        </div>
        {loading && (
          <Loader
            className="absolute ml-5"
            type="Rings"
            color="#00BFFF"
            height={40}
            width={40}
          />
        )}
        <input
          type="text"
          name="price"
          id="price"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder="0.00"
          ref={register}
          {...restProps}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            disabled={true}
            id="Currency"
            name="currency"
            className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            <option>{currency.toUpperCase()}</option>
          </select>
        </div>
      </div>
    </>
  );
}

MoneyInput.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  currency: PropTypes.string.isRequired,
};

MoneyInput.defaultProps = {
  loading: false,
};

export default MoneyInput;
