import axios from "axios";

const bankGovUaClient = axios.create({
  baseURL: "https://bank.gov.ua/NBUStatService/v1/statdirectory/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default bankGovUaClient;
