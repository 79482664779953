import React, { useState, useEffect } from "react";

import apiClient from "../api";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";
import MoneyInput from "../components/MoneyInput";
import { useForm } from "react-hook-form";

const IndexPage = () => {
  const [rate, setRate] = useState(null);
  const [calculatedResult, setCalculatedResult] = useState(null);

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    const contractRate = parseFloat(data.contractRate);
    const monthlyPayment = parseFloat(data.monthlyPayment);

    const diffValue = contractRate * 0.01;

    Cookies.set("monthlyPayment", monthlyPayment, { sameSite: "strict" });
    Cookies.set("contractRate", contractRate, { sameSite: "strict" });

    setCalculatedResult(
      rate >= contractRate + diffValue
        ? (monthlyPayment * (rate / contractRate)).toFixed(2)
        : monthlyPayment.toFixed(2)
    );
  };
  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    (async () => {
      const response = await apiClient.get("exchange?json");

      const usdCurrency = response.data.find(
        (dataRate) => dataRate.cc === "USD"
      );

      setRate(usdCurrency.rate);

      setValue("monthlyPayment", Cookies.get("monthlyPayment"), {
        shouldDirty: true,
      });
      setValue("contractRate", Cookies.get("contractRate"), {
        shouldDirty: true,
      });
    })();
  });

  return (
    <div className="container mx-auto">
      <div className="px-20 mt-10">
        <div className="text-center font-semibold">Курс от bank.gov.ua</div>

        {rate ? (
          <div className="text-center text-xl">{rate} грн</div>
        ) : (
          <div className="flex justify-center">
            <Loader type="Rings" color="#00BFFF" height={50} width={50} />
          </div>
        )}

        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"></svg>

        <div className="mt-10">
          <form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
            <MoneyInput
              loading={!rate}
              label="Месячный платеж"
              currency="uah"
              name="monthlyPayment"
              register={register({ required: true })}
            />
            <MoneyInput
              loading={!rate}
              label="Курс по договору"
              currency="uah"
              name="contractRate"
              register={register({ required: true })}
            />
            <div className="mt-2">
              <button className="py-2 px-4 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75">
                Посчитать
              </button>
            </div>
          </form>
        </div>

        {calculatedResult && (
          <div className="mt-3 ml-3 text-xl">₴ {calculatedResult} к оплате</div>
        )}
      </div>
    </div>
  );
};

export default IndexPage;
